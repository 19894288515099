import { ContactData } from './hubspot'

export function trackDeal(
  type: 'email' | 'full' | 'campaign' | 'trial',
  dealData: Partial<ContactData>,
) {
  window.dataLayer?.push({
    demo_request_name: `${dealData.firstName} ${dealData.lastName}`,
    demo_request_email: dealData.email,
    demo_request_company: dealData.companyName,
    demo_request_engineering_headcount: dealData.numberOfDevs,
    demo_request_issue_tracker: dealData.issueTrackers,
    demo_request_source_code: dealData.sourceCodeHosting,
    demo_request_ci_provider: dealData.ciProviders,
  })
  window.dataLayer?.push({
    event: `demo-request-submit-${type}`,
  })
}
