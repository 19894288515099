import { navigate } from 'gatsby'
import queryString from 'query-string'
import React, { useState } from 'react'
import { validateWorkEmailInput } from '../../formUtils'
import { trackDeal } from '../../googleTagManager'
import { useBase64EmailFromUrl } from '../../hooks'
import { sendContactDataToHS } from '../../hubspot'
import GetStartedForm, { ContactData } from './GetStartedForm'

type Props = {
  campaign?: { value: string; audience: string }
  formId: string
}

export function DemoForm({ campaign, formId }: Props) {
  const emailFromUrl = useBase64EmailFromUrl()
  const [email, setEmail] = useState(emailFromUrl || '')

  return (
    <div className="cta-form">
      <GetStartedForm
        formId={formId}
        campaign={campaign}
        email={email}
        setEmail={setEmail}
        onSubmit={onSubmit}
      />
    </div>
  )
}

function onSubmit(
  dealData: ContactData,
  inputRefs: { email: HTMLInputElement | null },
  setErrorMsg: (message: string) => void,
  setLoading: (value: boolean) => void,
  formId: string,
  isCampaign: boolean,
  isSmallTeam: boolean,
) {
  return async e => {
    e.preventDefault()

    // If you find yourself adding more than 1-3 rules to this custom validation logic,
    // please consider using something like react-hook-form to untangle the mess.
    if (!inputRefs.email || !validateWorkEmailInput(inputRefs.email)) {
      return
    }

    setLoading(true)
    try {
      const res = await sendContactDataToHS(dealData, formId)
      trackDeal(isCampaign ? 'campaign' : 'full', dealData)
      if (res.status === 200) {
        setErrorMsg('')
        await navigate(thankYouPageOrCalendarPickLink(dealData, isSmallTeam))
      } else {
        setErrorMsg('Oops, an error. Please try again.')
        // @ts-ignore
        Sentry.captureException(JSON.stringify(res))
      }
    } catch (error) {
      // @ts-ignore
      Sentry.captureException(error)
    } finally {
      setLoading(false)
    }
  }
}

function thankYouPageOrCalendarPickLink(
  dealData: ContactData,
  isSmallTeam: boolean,
) {
  // Source code hosting provider not supported
  // This check needs to be first to prevent small teams trying to install with other
  // than Github source code hosting
  if (!dealData.sourceCodeHosting?.includes('GitHub')) {
    const queryParams = queryString.stringify({
      origin: 'demo-request',
      target: 'source-code-hosting-not-supported',
      ...(dealData.sourceCodeHosting
        ? { sch: dealData.sourceCodeHosting }
        : {}),
    })
    return `/thank-you?${queryParams}`
  }

  // Small team install
  if (isSmallTeam) {
    const queryParams = queryString.stringify({
      origin: 'demo-request',
      target: 'small-team',
    })
    return `/thank-you?${queryParams}`
  } else {
    const queryParams = queryString.stringify({
      email: dealData.email,
      firstname: dealData.firstName,
      lastname: dealData.lastName,
      numberOfDevs: dealData.numberOfDevs,
    })
    return `/pick-a-time?${queryParams}`
  }
}

export default DemoForm

export const __test = {
  thankYouPageOrCalendarPickLink,
}
